import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NoMatch from '../components/NoMatch';
import { fetchAdminUsers, updateUserRole } from '../redux/modules/admin/actions';
import Loader from '../components/Loader';

class Admin extends Component {
  componentDidMount() {
    const { user: { uid } = {} } = this.props;
    this.props.getAdminUsers(uid);
  }
  render() {
    const { admin: { isFetching = true, users } = {}, user: { uid } = {} } = this.props;
    return (
      <div className="container mt-5 pt-3">
        <h3>Users Management</h3>
        {isFetching ? (
          <Loader />
        ) : (
          Object.keys(users).map((key, index) => {
            return (
              <div key={index} className="onTop card py-3 my-1 todo rounded-lg">
                <div className="d-flex flex-row w-100">
                  <div className="px-2">
                    <input
                      className="pointer"
                      type="checkbox"
                      checked={users[key].isAdmin}
                      onChange={(evt) => {
                        this.props.updateUserRole(users[key].uid, evt.target.checked).then(() => this.props.getAdminUsers(uid));
                      }}
                    />
                  </div>
                  <div>{users[key].displayName}</div>
                  <small className="py-1 pl-3">{users[key].email}</small>
                  <small className="py-1 pl-3">{users[key].lastSignInTime}</small>
                  <div className="mx-2 bg-success rounded ml-auto p-1" onClick={() => {}}>
                    {users[key].isAdmin ? 'Admin' : 'User'}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  }
}

Admin.propTypes = {
  user: PropTypes.object,
  admin: PropTypes.object,
  getAdminUsers: PropTypes.func,
  updateUserRole: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    getAdminUsers: (userId) => {
      return dispatch(fetchAdminUsers(userId));
    },
    updateUserRole: (userId, isAdmin) => {
      return dispatch(updateUserRole(userId, isAdmin));
    }
  };
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
