import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { text, type = 'info', className, onClick } = props;
  return (
    <div className={className}>
      <button type="button" className={`btn btn-${type}`} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link']),
  className: PropTypes.string,
  onClick: PropTypes.func
};
export default Button;
