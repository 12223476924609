import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class AdvanceDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasClicked: false
    };
  }
  onFirstClick = () => {
    this.setState({
      hasClicked: true
    });
  };
  render() {
    const { text, onClick, children } = this.props;
    return (
      <div>
        {this.state.hasClicked ? (
          <React.Fragment>
            <div className="d-flex flex-row">
              <div className="mt-2">Are you sure?</div>
              <Button variant="danger" className="btn-sm m-1" onClick={onClick}>
                Yes
              </Button>
              <Button
                variant="success"
                className="btn-sm m-1"
                onClick={() => {
                  this.setState({ hasClicked: false });
                }}
              >
                No
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button variant="danger" onClick={this.onFirstClick}>
              {text}
            </Button>
            {children}
          </React.Fragment>
        )}
      </div>
    );
  }
}

AdvanceDelete.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.any,
  text: PropTypes.any
};

export default AdvanceDelete;
