import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

export default class Roles {
  constructor() {
    const db = firebase.firestore();
    this.userDb = db.collection('users');
    this.companiesDb = db.collection('companies');
  }

  __getUserId = () => {
    const user = firebase.auth().currentUser;
    return user.uid;
  };

  __getCompanyId = async () => {
    const companyId = await this.userDb
      .doc(`${this.__getUserId()}`)
      .get()
      .then(async (doc) => {
        return await doc.data().company.id;
      });
    return companyId;
  };

  getUserRole = async () => {
    // Get existing User Role Id
    const companyId = await this.__getCompanyId();
    const roles = await this.companiesDb
      .doc(`${companyId}`)
      .get()
      .then((doc) => {
        const currentRoles = doc.data().roles || [];
        let userRoles = [];
        currentRoles.map((role) => {
          if (role.uid === this.__getUserId()) {
            userRoles.push(role.id);
          }
        });
        return userRoles;
      });
    return roles;
  };

  addUserRole = async () => {
    // create new roleId
    const companyId = await this.__getCompanyId();
    await this.companiesDb
      .doc(`${companyId}`)
      .get()
      .then(async (doc) => {
        const currentRoles = doc.data().roles || [];
        currentRoles.push({
          id: uuidv4(),
          uid: this.__getUserId()
        });
        await this.companiesDb.doc(`${companyId}`).update({
          roles: currentRoles
        });
      });
  };
}
