import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import copy from 'copy-to-clipboard';
import Database from '../Database';
import Header from '../components/Header';
import AddTeam from '../components/AddTeam';
import Image from '../components/Image';
import SubHeader from '../components/Header/SubHeader';
import AddMember from '../components/AddMember';
import Loader from '../components/Loader';
import { addUser, fetchTeams, createTeam, createTeamMember, createCompany } from '../redux/modules/teams/actions';
import Avatar from '../components/Avatar';
import Layout from '../components/Layout';
import UserProfile from '../components/Panel/UserProfile';
import AddCompany from '../components/AddCompany';
import NoMatch from '../components/NoMatch';
class Profile extends Component {
  constructor() {
    super();
    this.firebaseDB = new Database(firebase);
    this.state = {
      userInfo: {},
      showAddTeam: false,
      showAddMember: false,
      filter: {
        teams: undefined
      }
    };
  }
  componentDidMount() {
    const { user } = this.props;
    this.props.addUser(user).then(() => {
      this.props.fetchTeams();
    });
  }

  pushHistory = (loc) => {
    const { history } = this.props;
    history.push(loc);
  };

  onFilter = (evt) => {
    const filterKey = evt.target.value;
    const teams = JSON.parse(JSON.stringify(this.props.teams.list));
    teams.map((team) => {
      team.members = team.members && team.members.filter((member) => member.name.toLowerCase().includes(filterKey.toLowerCase()));
      return team;
    });
    if (filterKey.trim().length > 0) {
      this.setState({
        filter: {
          teams: teams
        }
      });
    } else {
      this.setState({
        filter: {
          teams: undefined
        }
      });
    }
  };

  renderMembers = (teamId, members) => {
    if (!members) return <NoMatch text={'No Member'} />;
    return (
      <React.Fragment>
        {members.map((member) => {
          if (!member.image) {
            let totalTask = 0,
              completedTask = 0;
            member.tasks &&
              member.tasks.map((task) => {
                if (task.status === 'Completed') {
                  completedTask++;
                }
                totalTask++;
              });
            return (
              <Avatar
                key={member.name}
                name={member.name}
                badge={totalTask ? `${completedTask}/${totalTask}` : null}
                size={'medium'}
                onClick={() => {
                  this.pushHistory(`/${teamId}/${member.id}`);
                }}
              />
            );
          }
          return <Image key={member.name} name={member.name} imgSrc={member.image} />;
        })}
      </React.Fragment>
    );
  };

  onAddTeamClick = () => {
    this.setState({
      showAddTeam: true
    });
  };

  onAddMemberClick = (teamId) => {
    this.setState({
      showAddMember: true,
      selectedTeamId: teamId
    });
  };

  render() {
    const { user, teams: { isFetching = true, list: teamsList = [], company, filestore } = {} } = this.props;
    const {
      filter: { teams: filterTeams }
    } = this.state;
    if (isFetching) {
      return <Loader />;
    }
    return (
      <div className="p-3">
        <input className="BsSearch container w-50 form-control" type="text" placeholder="Search Employee" aria-label="Search" onChange={this.onFilter} />
        <Layout
          leftContent={
            <React.Fragment>
              <UserProfile user={user} files={filestore} />
            </React.Fragment>
          }
          rightContent={
            <React.Fragment>
              <div className="jumbotron jumbotron-fluid border-radius-lg pt-4 pb-4">
                <div className="container">
                  <Header
                    className="row"
                    title={`${company ? company.name : ''}`}
                    onClick={() => {
                      copy(company.id);
                    }}
                    onAddClick={this.onAddTeamClick}
                  />
                  {(filterTeams || teamsList).map((team, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="w-100 bg-white my-3 border-right-top-4">
                          <SubHeader className="row pb-1 px-3" title={team.name} onClick={() => this.onAddMemberClick(team.id)} />
                          <div className="row d-flex pl-4 pb-2">{this.renderMembers(team.id, team.members)}</div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <AddCompany
                show={!company && !isFetching}
                onClose={() => {
                  // this.setState({ showAddCompany: false });
                }}
                onSave={(company) => {
                  this.props.createCompany(company);
                  // this.setState({ showAddCompany: false });
                }}
              />
              <AddTeam
                show={this.state.showAddTeam}
                onClose={() => {
                  this.setState({ showAddTeam: false });
                }}
                onSave={(team) => {
                  this.props.createTeam(team);
                  this.setState({ showAddTeam: false });
                }}
              />
              <AddMember
                show={this.state.showAddMember}
                onClose={() => {
                  this.setState({ showAddMember: false, selectedTeamId: undefined });
                }}
                onSave={(member) => {
                  this.props.addTeamMember(this.state.selectedTeamId, member);
                  this.setState({ showAddMember: false, selectedTeamId: undefined });
                }}
              />
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

Profile.propTypes = {
  teams: PropTypes.object,
  addTeamMember: PropTypes.func,
  addUser: PropTypes.func,
  createCompany: PropTypes.func,
  createTeam: PropTypes.func,
  fetchTeams: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  user: PropTypes.any
};

function mapDispatchToProps(dispatch) {
  return {
    addUser: (user) => {
      return dispatch(addUser(user));
    },
    createCompany: (company) => {
      dispatch(createCompany(company));
    },
    fetchTeams: () => {
      return dispatch(fetchTeams());
    },
    createTeam: (team) => {
      dispatch(createTeam(team));
    },
    addTeamMember: (teamId, member) => {
      dispatch(createTeamMember(teamId, member));
    }
  };
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
