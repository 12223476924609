import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaPlus } from 'react-icons/fa';
import './_header.scss';

class Header extends Component {
  render() {
    const { title, className, onClick, onAddClick } = this.props;
    return (
      <div className={`${className}`}>
        <div className="w-100 p-3 mb-2 bg-primary text-white d-flex flex-row rounded-left">
          <div onClick={onClick} className="pointer py-2" title="Click to copy Company ID">
            {title}
          </div>
          <div className="pl-3">
            <button className="rounded-pill px-2 py-1" onClick={onAddClick}>
              <FaPlus className="m-1" />
              Add Team
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  className: PropTypes.any,
  onAddClick: PropTypes.any,
  onClick: PropTypes.any,
  title: PropTypes.any
};

export default Header;
