import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
class AddTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    };
  }

  onSave = () => {
    const { onSave } = this.props;
    onSave(this.state);
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  onInputNameChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };

  render() {
    const { show } = this.props;
    return (
      <Modal show={show} onHide={this.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>New TEAM !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="col-form-label">Team Name:</label>
            <input type="text" className="form-control" onChange={this.onInputNameChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onClose}>
            Close
          </Button>
          <Button variant="success" onClick={this.onSave}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddTeam.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  show: PropTypes.any
};

export default AddTeam;
