const handleRequest = (state = {}) => {
  return {
    ...state,
    isFetching: true,
    isError: false
  };
};

const handleReceive = (state = {}) => {
  return {
    ...state,
    isFetching: false,
    isError: false
  };
};

const handleError = (state = {}) => {
  return {
    ...state,
    isFetching: false,
    isError: true
  };
};

const handleReceiveData = (state = {}, action) => {
  const { payload: { data } = {} } = action;

  return {
    ...state,
    data
  };
};

const handleCreateRequest = (state) => {
  return {
    ...state,
    isCreating: true,
    isError: false
  };
};

const handleCreatedRequest = (state) => {
  return {
    ...state,
    isCreating: false,
    isError: false
  };
};

const handleCreateRequestFail = (state) => {
  return {
    ...state,
    isCreating: false,
    isError: true
  };
};

export { handleRequest, handleReceive, handleError, handleReceiveData, handleCreateRequest, handleCreatedRequest, handleCreateRequestFail };
