import { createAction } from 'redux-actions';
import firebase from 'firebase/app';

import { appName } from '../../../constants';
import Database from '../../../Database';
const namespace = `${appName}/teams`;

export const REQUEST_COMPANY = `${namespace}/REQUEST_COMPANY`;
export const REQUEST_TEAMS = `${namespace}/REQUEST_TEAMS`;
export const RECEIVED_COMPANY = `${namespace}/RECEIVED_COMPANY`;
export const RECEIVED_TEAMS = `${namespace}/RECEIVED_TEAMS`;
export const CREATE_TEAMS = `${namespace}/CREATE_TEAM`;
export const CREATE_MEMBER_TASK = `${namespace}/CREATE_MEMBER_TASK`;
export const UPDATE_MEMBER_TASK = `${namespace}/UPDATE_MEMBER_TASK`;
export const RECEIVED_FILESTORE_FILES = `${namespace}/RECEIVED_FILESTORE_FILES`;
export const DELETE_MEMBER_TASK = `${namespace}/DELETE_MEMBER_TASK`;
// fetch ads from different api internal constant by goalId
const requestCompany = createAction(REQUEST_COMPANY);
const requestTeams = createAction(REQUEST_TEAMS);
const receivedCompany = createAction(RECEIVED_COMPANY);
const receivedTeams = createAction(RECEIVED_TEAMS);
const createTeams = createAction(CREATE_TEAMS);
const createMemberTask = createAction(CREATE_MEMBER_TASK);
const updateMemberTask = createAction(UPDATE_MEMBER_TASK);
const receivedFilestoreFiles = createAction(RECEIVED_FILESTORE_FILES);
const deleteMemberTask = createAction(DELETE_MEMBER_TASK);

export const addUser = (user) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    return firebaseDB.addUser(user);
  };
};

export const fetchTeams = () => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    dispatch(requestCompany());
    return firebaseDB.getCompany().then((company) => {
      dispatch(receivedCompany(company));
      dispatch(requestTeams());
      return firebaseDB.getTeams().then((teams) => {
        dispatch(receivedTeams(teams));
        return firebaseDB.getFilestoreFiles().then((files) => {
          dispatch(receivedFilestoreFiles(files));
        });
      });
    });
  };
};

export const createCompany = (company) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    firebaseDB.addCompany(company).then(() => {
      dispatch(fetchTeams());
    });
  };
};

export const createTeam = (team) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    dispatch(createTeams(team));
    firebaseDB.addTeam(team).then(() => {
      dispatch(fetchTeams());
    });
  };
};

export const createTeamMember = (teamId, member) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    dispatch(requestTeams());
    firebaseDB.addTeamMember(teamId, member).then(() => {
      dispatch(fetchTeams());
    });
  };
};

export const addTeamMemberTask = (teamId, memberId, task) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    dispatch(createMemberTask(task));
    firebaseDB.addTeamMemberTask(teamId, memberId, task).then(() => {
      dispatch(fetchTeams());
    });
  };
};

export const updateTeamMemberTask = (teamId, memberId, taskId, updatedTask) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    dispatch(updateMemberTask(taskId));
    return firebaseDB.updateTeamMemberTask(teamId, memberId, taskId, updatedTask).then(() => {
      dispatch(fetchTeams());
    });
  };
};

export const deleteTeamMemberTask = (teamId, memberId, taskId) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    dispatch(deleteMemberTask(taskId));
    return firebaseDB.deleteTeamMemberTask(teamId, memberId, taskId).then(() => {
      dispatch(fetchTeams());
    });
  };
};
