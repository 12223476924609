import { createAction } from 'redux-actions';
import firebase from 'firebase/app';

import { appName } from '../../../constants';
import Database from '../../../Database';
const namespace = `${appName}/admin`;

export const REQUEST_ADMIN_USERS = `${namespace}/REQUEST_ADMIN_USERS`;
export const RECEIVED_ADMIN_USERS = `${namespace}/RECEIVED_ADMIN_USERS`;

// fetch ads from different api internal constant by goalId
const requestAdminUsers = createAction(REQUEST_ADMIN_USERS);
const receivedAdminUsers = createAction(RECEIVED_ADMIN_USERS);

export const fetchAdminUsers = (userId) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    dispatch(requestAdminUsers());
    firebaseDB.getAdminUsers().then((usersObj) => {
      if (userId in usersObj && usersObj[userId].isAdmin) {
        dispatch(receivedAdminUsers(usersObj));
      } else {
        dispatch(receivedAdminUsers({}));
      }
    });
  };
};

export const updateUserRole = (userId, isAdmin) => {
  return (dispatch, getState) => {
    const firebaseDB = new Database(firebase);
    return firebaseDB.updateUserRole(userId, isAdmin);
  };
};
