import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaPlus } from 'react-icons/fa';

class SubHeader extends Component {
  render() {
    const { title, className, onClick } = this.props;
    return (
      <div className={`${className}`}>
        <div className="w-100 p-3 mb-2 bg-black text-white d-flex flex-row rounded-left">
          <div className="py-2">{title}</div>
          <div className="pl-3">
            <button className="rounded-pill px-2 py-1" onClick={onClick}>
              <FaPlus className="m-1" />
              Add Member
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SubHeader.propTypes = {
  className: PropTypes.any,
  onClick: PropTypes.any,
  title: PropTypes.any
};

export default SubHeader;
