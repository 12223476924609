import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import Roles from './Roles';

export default class Database {
  // userDb;
  constructor() {
    const db = firebase.firestore();
    this.userDb = db.collection('users');
    this.companiesDb = db.collection('companies');
  }

  addUser = async (user) => {
    const { uid, displayName, photoURL, email, emailVerified, phoneNumber, metadata: { lastSignInTime } = {} } = user;
    await this.userDb
      .doc(uid)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          this.userDb.doc(`${uid}`).set({
            uid,
            displayName,
            photoURL,
            email,
            emailVerified,
            phoneNumber,
            lastSignInTime
          });
        } else {
          const dbUserLastSignInTime = doc.data().lastSignInTime;
          if (dbUserLastSignInTime !== lastSignInTime) {
            this.userDb.doc(`${uid}`).update({
              displayName,
              photoURL,
              emailVerified,
              phoneNumber,
              lastSignInTime
            });
          }
        }
      });
  };

  getUserId = () => {
    const user = firebase.auth().currentUser;
    return user.uid;
  };

  getUser = (uid) => {
    return this.userDb
      .doc(uid)
      .get()
      .then((doc) => {
        return doc.data();
      });
  };

  getCompanyId = () => {
    const user = firebase.auth().currentUser;
    return this.userDb
      .doc(`${user.uid}`)
      .get()
      .then((doc) => {
        let companyId = uuidv4();
        if (doc.data().company && doc.data().company.id) {
          companyId = doc.data().company.id;
        }
        return companyId;
      });
  };

  addTeam = async (team) => {
    // const user = firebase.auth().currentUser;
    const teams = await this.getTeams();
    const companyId = await this.getCompanyId();
    return this.companiesDb.doc(`${companyId}`).update({
      teams: [
        ...teams,
        {
          id: uuidv4(),
          ...team
        }
      ]
    });
  };

  addCompany = async (company) => {
    const user = firebase.auth().currentUser;
    if (company.id) {
      // connect company to user
      await this.companiesDb
        .doc(`${company.id}`)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            await this.userDb.doc(`${user.uid}`).update({
              company: { id: company.id }
            });
            const _Roles = new Roles();
            await _Roles.addUserRole(user.uid, company.id);
          }
        });
    } else {
      // creating new company
      const _company = {
        ...company,
        id: company.id || uuidv4()
      };
      await this.companiesDb
        .doc(`${_company.id}`)
        .get()
        .then(async (doc) => {
          if (!doc.exists) {
            const companyData = {
              filestore: [],
              company: _company,
              teams: []
            };
            await this.companiesDb
              .doc(`${_company.id}`)
              .set({
                ...companyData
              })
              .then(async () => {
                await this.userDb
                  .doc(`${user.uid}`)
                  .update({
                    company: { id: _company.id }
                  })
                  .then(async () => {
                    const _Roles = new Roles();
                    _Roles.addUserRole(user.uid, _company.id);
                  });
              });
          }
        });
    }
  };

  getCompany = () => {
    // this.syncMigrate();
    const user = firebase.auth().currentUser;
    return this.userDb
      .doc(`${user.uid}`)
      .get()
      .then((doc) => {
        if (!doc.data().company) {
          return undefined;
        } else {
          const companyId = doc.data().company.id;
          return this.companiesDb
            .doc(`${companyId}`)
            .get()
            .then((doc) => {
              return doc.data().company;
            });
        }
      });
  };

  getTeams = () => {
    const user = firebase.auth().currentUser;
    return this.userDb
      .doc(`${user.uid}`)
      .get()
      .then((doc) => {
        if (!doc.data().company) {
          return [];
        } else {
          const companyId = doc.data().company.id;
          return this.companiesDb
            .doc(`${companyId}`)
            .get()
            .then(async (doc) => {
              const _Roles = new Roles();
              const roleIds = await _Roles.getUserRole();
              const teams = doc.data().teams || [];
              let filteredTeam = this.filterTeamByRole(teams, roleIds);
              return filteredTeam;
            });
        }
      });
  };

  filterTeamByRole = (teams, roleIds) => {
    // Assumption. THERE ARE ONLY TWO ROLES
    // User role and Admin
    let hasAdminRole = false;
    roleIds.map((role) => {
      if (role === 'admin') {
        hasAdminRole = true;
      }
    });
    if (hasAdminRole) {
      return teams;
    }
    let filteredTeam = [];
    teams.map((team) => {
      let tmpTeam = team;
      tmpTeam.members = tmpTeam.members && tmpTeam.members.filter((member) => member.role === roleIds[0]);
      filteredTeam.push(tmpTeam);
    });
    return filteredTeam;
  };

  addTeamMember = async (teamId, member) => {
    const companyId = await this.getCompanyId();
    let teams = await this.getTeams();
    const _Roles = new Roles();
    let roleIds = await _Roles.getUserRole(this.getUserId(), companyId);
    if (!roleIds || roleIds.length === 0) {
      await _Roles.addUserRole(this.getUserId(), companyId);
      roleIds = await _Roles.getUserRole(this.getUserId(), companyId);
    }

    roleIds = roleIds.filter((role) => role !== 'admin');

    teams.map((team) => {
      if (team.id === teamId) {
        if (team.members === undefined) {
          team.members = [];
        }
        team.members.push({
          id: uuidv4(),
          ...member,
          role: roleIds[0]
        });
      }
      return team;
    });
    return this.companiesDb.doc(`${companyId}`).update({
      teams: teams
    });
  };

  addTeamMemberTask = async (teamId, memberId, task) => {
    let teams = await this.getTeams();

    teams.map((team) => {
      if (team.id === teamId) {
        team.members.map((member) => {
          if (member.id === memberId) {
            if (member.tasks === undefined) {
              member.tasks = [];
            }
            member.tasks.push({
              id: uuidv4(),
              dateTime: new Date().toISOString(),
              ...task
            });
          }
        });
      }
      return team;
    });

    const companyId = await this.getCompanyId();
    return this.companiesDb.doc(`${companyId}`).update({
      teams: teams
    });
  };

  updateTeamMemberTask = async (teamId, memberId, taskId, updatedTask) => {
    let teams = await this.getTeams();

    teams.map((team) => {
      if (team.id === teamId) {
        team.members.map((member) => {
          if (member.id === memberId) {
            member.tasks.map((task) => {
              if (task.id === taskId) {
                task.value = updatedTask.value;
                task.status = updatedTask.status;
                task.year = updatedTask.year;
                task.comments = updatedTask.comments;
                task.todos = updatedTask.todos;
                task.tags = updatedTask.tags;
              }
              return task;
            });
          }
          return member;
        });
      }
      return team;
    });

    const companyId = await this.getCompanyId();
    return this.companiesDb.doc(`${companyId}`).update({
      teams: teams
    });
  };

  deleteTeamMemberTask = async (teamId, memberId, taskId) => {
    let teams = await this.getTeams();

    let _teams = teams.map((team) => {
      if (team.id === teamId) {
        team.members.map((member) => {
          if (member.id === memberId) {
            let _tasks = member.tasks;
            _tasks = _tasks.filter((task) => task.id !== taskId);
            member.tasks = _tasks;
          }
          return member;
        });
      }
      return team;
    });

    const companyId = await this.getCompanyId();
    return this.companiesDb.doc(`${companyId}`).update({
      teams: _teams
    });
  };

  // Filestore
  getFilestoreFiles = () => {
    const user = firebase.auth().currentUser;
    return this.userDb
      .doc(`${user.uid}`)
      .get()
      .then((doc) => {
        if (!doc.data().company) {
          return [];
        } else {
          const companyId = doc.data().company.id;
          return this.companiesDb
            .doc(`${companyId}`)
            .get()
            .then((doc) => {
              return doc.data().filestore || [];
            });
        }
      });
  };

  addToFilestore = async (file) => {
    let files = await this.getFilestoreFiles();
    files.push({
      id: uuidv4(),
      ...file
    });
    const companyId = await this.getCompanyId();
    return this.companiesDb.doc(`${companyId}`).update({
      filestore: files
    });
  };

  addToFilestoreForMember = async (file, teamId, memberId) => {
    let teams = await this.getTeams();

    teams.map((team) => {
      if (team.id === teamId) {
        team.members.map((member) => {
          if (member.id === memberId) {
            if (member.filestore === undefined) {
              member.filestore = [];
            }
            member.filestore.push({
              id: uuidv4(),
              ...file
            });
          }
        });
      }
      return team;
    });

    const companyId = await this.getCompanyId();
    return this.companiesDb.doc(`${companyId}`).update({
      teams: teams
    });
  };

  // Admin Users
  getAdminUsers = async () => {
    let groupedUsersRole = {};
    const companyId = await this.getCompanyId();
    const userRoles = await this.companiesDb
      .doc(`${companyId}`)
      .get()
      .then(async (doc) => {
        return doc.data().roles || [];
      });
    // eslint-disable-next-line
    await Promise.all(
      userRoles.map(async (user) => {
        const usr = await this.getUser(user.uid);
        groupedUsersRole[user.uid] = {
          displayName: usr.displayName,
          email: usr.email,
          lastSignInTime: usr.lastSignInTime,
          uid: user.uid,
          isAdmin: (groupedUsersRole[user.uid] && groupedUsersRole[user.uid].isAdmin) || user.id === 'admin'
        };
      })
    );

    return groupedUsersRole;
  };
  updateUserRole = async (userId, isAdmin) => {
    const companyId = await this.getCompanyId();
    const userRoles = await this.companiesDb
      .doc(`${companyId}`)
      .get()
      .then(async (doc) => {
        let roles = doc.data().roles || [];
        if (isAdmin) {
          roles.push({ uid: userId, id: 'admin' });
        } else {
          roles = roles.filter((role) => !(role.uid === userId && role.id === 'admin'));
        }
        return roles;
      });
    return this.companiesDb.doc(`${companyId}`).update({
      roles: userRoles
    });
  };

  // Filestore
  deleteLoggedInUserFiles = async (toBeDeletedFile) => {
    const companyId = await this.getCompanyId();
    const userRoles = await this.companiesDb
      .doc(`${companyId}`)
      .get()
      .then(async (doc) => {
        const userFiles = doc.data().filestore;
        let _userFiles = [...userFiles];
        _userFiles = _userFiles.filter((file) => file.id !== toBeDeletedFile.id);
        return this.companiesDb.doc(`${companyId}`).update({
          filestore: _userFiles
        });
      });
  };
  deleteMembersFile = async (toBeDeletedFile) => {
    const companyId = await this.getCompanyId();
    const userRoles = await this.companiesDb
      .doc(`${companyId}`)
      .get()
      .then(async (doc) => {
        const teams = doc.data().teams;
        let _teams = [...teams];
        _teams = _teams.map((team) => {
          team.members.map((member) => {
            member.filestore = member.filestore.filter((file) => file.id !== toBeDeletedFile.id);
            return member;
          });
          return team;
        });
        return this.companiesDb.doc(`${companyId}`).update({
          teams: _teams
        });
      });
  };
}
