import React from 'react';
import NoMatch from '../components/NoMatch';

const styles = {
  message: {
    fontSize: '22px',
    color: 'black',
    position: 'relative',
    fontFamily: "'Josefin Sans', sans-serif",
    fontWeight: 600,
    textTransform: 'uppercase',
    marginLeft: '-9px',
    padding: '12px 0px 45px 0px'
  },
  name: {
    fontFamily: "'Josefin Sans', sans-serif",
    lineHeight: 1,
    textTransform: 'capitalize',
    color: '#00c8da',
    fontSize: '72px',
    marginLeft: '20px',
    fontWeight: 700
  },
  title: {
    fontFamily: "'Josefin Sans', sans-serif",
    lineHeight: 1,
    textTransform: 'capitalize',
    color: '#00c8da',
    fontSize: '30px',
    paddingTop: '40px',
    marginLeft: '20px',
    fontWeight: 300
  }
};

const ContactUs = () => {
  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-md-5 col-lg-4">
          <img src="/img/HZ.jpeg" width="250" style={{ border: '5px solid rgb(0, 200, 218)' }} alt="" />
        </div>
        <div className="col-md-5 col-lg-8">
          <img src="/img/hello-stranger.png" width="445" className="mb-5 pt-3" alt="" />
          <div style={styles.name}>Mr. Haseeb Zulfiqar</div>
          <div style={styles.title}>Engineering Manager @ INVIDI Technologies</div>
        </div>
      </div>
      <div className="py-1 bg-black w-100"></div>
      <NoMatch text="Coming Soon !!!" className="h4 pt-5" />
    </div>
  );
};

export default ContactUs;
