import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FirebaseUploader from '../../Storage';
import DeleteButton from '../Button/DeleteButton';
import './_documentsUploader.scss';
class DocumentsUploader extends Component {
  render() {
    const { files, uploadFilePath, onSuccessCallback, onDelete } = this.props;
    return (
      <React.Fragment>
        <div className="d-flex flex-column p-0 m-0 mt-2">
          <ul className="list-group">
            <li className="list-group-item text-light bg-dark">Uploaded Documents</li>

            {files.length === 0 ? (
              <li className="list-group-item disable text-truncate text-muted">No files</li>
            ) : (
              files.map((file, index) => {
                return (
                  <li key={index} className="list-group-item uploader px-1">
                    <div className="float-right">
                      <DeleteButton className="mr-2 ml-auto pointer" onClick={() => onDelete(file)} />
                    </div>
                    <div className="ml-3">
                      <a target="_blank" rel="noopener noreferrer" href={file.link} className="text-break">
                        {file.filename}
                      </a>
                    </div>
                  </li>
                );
              })
            )}
          </ul>
        </div>
        <div className="row pt-2">
          <FirebaseUploader onSuccessCallback={onSuccessCallback} path={uploadFilePath} onErrorCallback={() => {}} btnText={'Upload'} className={'pl-3'} />
        </div>
      </React.Fragment>
    );
  }
}

DocumentsUploader.propTypes = {
  files: PropTypes.array,
  onSuccessCallback: PropTypes.func,
  uploadFilePath: PropTypes.any,
  onDelete: PropTypes.func
};

export default DocumentsUploader;
