import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './_avatar.scss';

const Avatar = (props) => {
  const { name, onClick, size, badge, edit = false } = props;
  const userArr = name.split(' ');
  let colors = ['avatar-color-1', 'avatar-color-2', 'avatar-color-3', 'avatar-color-4', 'avatar-color-5', 'avatar-color-6'];
  let random_color = colors[Math.floor(Math.random() * colors.length)];

  const editCss = classnames('badge mt-4', {
    'pt-5 pointer': size === 'large'
  });

  const clickableCss = classnames({
    btn: onClick
  });

  const columnCss = classnames({
    'd-flex flex-column': size === 'large'
  });

  const sizeCss = classnames('avatar-circle', {
    'avatar-circle-lg': size === 'large',
    'avatar-circle-sm': size === 'small'
  });

  return (
    <div key={`${name}-avatar`} className={`${columnCss} ${clickableCss} ${sizeCss} ${random_color}`} onClick={onClick}>
      <span className="initials">
        {userArr[0] ? userArr[0][0] : ''}
        {userArr[1] ? userArr[1][0] : ''}
      </span>
      {size === 'large' && edit && <div className={editCss}>Edit</div>}
      {badge ? <span className="badge badge-success mt-4 rounded-circle">{badge}</span> : null}
    </div>
  );
};

Avatar.propTypes = {
  badge: PropTypes.any,
  name: PropTypes.string,
  onClick: PropTypes.any,
  size: PropTypes.oneOf(['large', 'medium', 'small']).isRequired,
  edit: PropTypes.bool
};

export default Avatar;
