import PropTypes from 'prop-types';
import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { v4 as uuidv4 } from 'uuid';

class FirebaseUploader extends Component {
  state = {
    filenames: [],
    downloadURLs: [],
    filename: null,
    isUploading: false,
    uploadProgress: 0
  };

  user = firebase.auth().currentUser;

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });

  handleProgress = (progress) =>
    this.setState({
      uploadProgress: progress
    });

  handleUploadError = (error) => {
    const { onErrorCallback } = this.props;

    this.setState({
      isUploading: false
    });
    onErrorCallback();
  };

  handleUploadSuccess = async (filename) => {
    const { onSuccessCallback, path } = this.props;
    const storageRefPath = path ? `${this.user.uid}/docs/members/${path}` : `${this.user.uid}/docs`;
    const downloadURL = await firebase.storage().ref(storageRefPath).child(filename).getDownloadURL();

    this.setState((oldState) => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: [...oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false
    }));

    onSuccessCallback({
      dateTime: new Date().toISOString(),
      filename: this.state.filename,
      link: downloadURL,
      extension: filename.split('.')[1]
    });
  };

  generateFileName = (file) => {
    this.setState({ filename: file.name });
    return `${uuidv4()}-${file.name}`;
  };

  render() {
    const { className, label, btnText = 'Upload', path } = this.props;
    const { isUploading } = this.state;
    const storageRefPath = path ? `${this.user.uid}/docs/members/${path}` : `${this.user.uid}/docs`;
    return (
      <div className={className}>
        {label ? <label>{label}</label> : null}
        <CustomUploadButton
          accept="*/*"
          name="image-uploader-multiple"
          // randomizeFilename
          filename={this.generateFileName}
          storageRef={firebase.storage().ref(storageRefPath)}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
          onProgress={this.handleProgress}
          // multiple
          className={'btn btn-outline-success btn-sm'}
        >
          {btnText}
        </CustomUploadButton>
        {isUploading ? <p>Progress: {this.state.uploadProgress}</p> : null}
        {/* {this.state.filenames.length > 0 ? (
          <p>Filenames: {this.state.filenames.join(', ')}</p>
        ) : null} */}

        {/* <div>
          {this.state.downloadURLs.map((downloadURL, i) => {
            return <img key={i} src={downloadURL} alt="" />;
          })}
        </div> */}
      </div>
    );
  }
}

FirebaseUploader.propTypes = {
  btnText: PropTypes.string,
  className: PropTypes.any,
  label: PropTypes.any,
  onErrorCallback: PropTypes.func,
  onSuccessCallback: PropTypes.func,
  path: PropTypes.any
};

export default FirebaseUploader;
