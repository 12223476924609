import React from 'react';
import { PulseLoader } from 'react-spinners';

const Loader = () => {
  return (
    <div className={'d-flex justify-content-center align-items-center mt-5 pt-5'}>
      <PulseLoader size={20} margin={6} color={'rgb(54, 215, 183)'} />
    </div>
  );
};

export default Loader;
