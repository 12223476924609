import { handleActions as reducer } from 'redux-actions';
import { REQUEST_TEAMS, RECEIVED_TEAMS, REQUEST_COMPANY, RECEIVED_COMPANY, RECEIVED_FILESTORE_FILES } from './actions';

import { handleReceive, handleRequest } from '../../selectors';

const handleRequestCompany = (state) => {
  return {
    ...handleRequest(state)
  };
};

const handleRequestTeams = (state) => {
  return {
    ...handleRequest(state)
  };
};

const handleReceiveCompany = (state, action) => {
  return {
    ...handleReceive(state),
    company: action.payload
  };
};

const handleReceiveTeams = (state, action) => {
  return {
    ...handleReceive(state),
    list: action.payload
  };
};

const handleReceiveFilestoreFiles = (state, action) => {
  return {
    ...handleReceive(state),
    filestore: action.payload
  };
};

export default reducer(
  {
    [REQUEST_TEAMS]: handleRequestTeams,
    [RECEIVED_TEAMS]: handleReceiveTeams,
    [REQUEST_COMPANY]: handleRequestCompany,
    [RECEIVED_COMPANY]: handleReceiveCompany,
    [RECEIVED_FILESTORE_FILES]: handleReceiveFilestoreFiles
  },
  {}
);
