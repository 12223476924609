import PropTypes from 'prop-types';
import React from 'react';

const NoMatch = (props) => {
  const { text = 'Oops!! Seems like you are in wrong place.', className } = props;
  return <div className={`w-100 p-3 justify-content-between align-items-center text-muted text-center ${className}`}>{text}</div>;
};

NoMatch.propTypes = {
  className: PropTypes.any,
  text: PropTypes.string
};

export default NoMatch;
