import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ToDo from '../ToDo';
import AdvanceDelete from '../AdvanceDelete';
import TextInput from '../Inline/TextInput';
import AdvanceTextArea from '../AdvanceTextArea';
class EditTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: '',
      value: null,
      status: 'Active',
      year: new Date().getFullYear(),
      comments: '',
      todos: []
    };
  }

  resetState = () => {
    this.setState({
      tags: '',
      value: null,
      status: 'Active',
      year: new Date().getFullYear(),
      comments: '',
      todos: []
    });
  };

  onSave = () => {
    const { onSave } = this.props;
    onSave(this.state);
  };

  onClose = () => {
    const { onClose = () => {} } = this.props;
    this.resetState();
    onClose();
  };

  onDelete = () => {
    const { onDelete = () => {}, task } = this.props;
    this.resetState();
    onDelete(task.id);
  };

  onInputNameChange = (event) => {
    this.setState({
      value: event.target.value
    });
  };

  onInputTaskChange = (event) => {
    this.setState({
      comments: event.target.value
    });
  };

  componentDidMount() {
    const {
      task,
      task: { todos }
    } = this.props;
    this.setState({
      value: task.value,
      tags: task.tags || this.state.tags,
      comments: task.comments || this.state.comments,
      status: task.status || this.state.status,
      year: task.year || this.state.year,
      todos: (todos && [...todos]) || this.state.todos
    });
  }

  handleSelectChange = (evt, key) => {
    this.setState({ [key]: evt.target.value });
  };

  generateYearsSelect = () => {
    let yearArr = [],
      min = new Date().getFullYear() - 4,
      max = min + 10;
    for (let i = min; i <= max; i++) {
      yearArr.push(i);
    }
    return yearArr;
  };

  render() {
    const { show } = this.props;
    if (!show || !this.state.value) return null;
    return (
      <Modal size={'lg'} show={show} onHide={this.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex mb-3 float-right">
            <TextInput label="Tags" className="ml-auto" value={this.state.tags} placeholder={'Tags'} onChange={(val) => this.setState({ tags: val })} />
          </div>
          <div className="form-group pt-0">
            <label className="col-form-label">Goal:</label>
            <AdvanceTextArea className="pt-3 onTop" value={this.state.value} onChange={this.onInputNameChange} />
            <ToDo className={'pt-4 border-top my-3'} label={'Tasks'} todos={this.state.todos} onChange={(todos) => this.setState({ todos: todos })} />
            <div className="d-flex bd-highlight mb-3 border-top my-3">
              <div className="p-2 bd-highlight">
                <label className="mt-2 mr-1">Status: </label>
                <select value={this.state.status} onChange={(evt) => this.handleSelectChange(evt, 'status')} title="Status">
                  <option value="Active">Active</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div className="ml-auto p-2 bd-highlight">
                <label className="mt-2 mr-1">Year: </label>
                <select value={this.state.year} onChange={(evt) => this.handleSelectChange(evt, 'year')} title="Year">
                  {this.generateYearsSelect().map((year, index) => {
                    return (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="border-top my-0">
              <label className="col-form-label">Comments:</label>
              <textarea type="text" className="form-control" defaultValue={this.state.comments} rows={5} onChange={this.onInputTaskChange} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <AdvanceDelete text={'Delete'} onClick={this.onDelete} />
            </div>
            <div className="ml-auto p-2 bd-highlight">
              <Button variant="secondary mr-1" onClick={this.onClose}>
                Close
              </Button>
              <Button variant="success" onClick={this.onSave}>
                Update
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

EditTask.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  show: PropTypes.any,
  task: PropTypes.shape({
    comments: PropTypes.any,
    id: PropTypes.any,
    status: PropTypes.any,
    tags: PropTypes.any,
    value: PropTypes.any,
    year: PropTypes.any,
    todos: PropTypes.any
  })
};

export default EditTask;
