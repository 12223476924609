import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: undefined,
      id: undefined
    };
  }

  onSave = () => {
    const { onSave } = this.props;
    onSave(this.state);
  };
  onInputIdChange = (event) => {
    this.setState({
      id: event.target.value
    });
  };
  onInputNameChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };

  render() {
    const { show } = this.props;
    return (
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Welcome to eTrack !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="col-form-label">Connect to your organization with Company ID</label>
            <input type="text" className="form-control" disabled={this.state.name} onChange={this.onInputIdChange} />
            <div className="p-2 pt-4">OR</div>
            <label className="col-form-label">Create new company name</label>
            <input type="text" className="form-control" disabled={this.state.id} onChange={this.onInputNameChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onSave}>
            {this.state.id ? 'Connect' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddCompany.propTypes = {
  onSave: PropTypes.func,
  show: PropTypes.any
};

export default AddCompany;
