import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../Button';
import { isEmptyString } from '../../Utils';
import { v4 as uuidv4 } from 'uuid';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './_todo.scss';
import DeleteButton from '../Button/DeleteButton';

const SortableItem = SortableElement(({ todo, onCheckBoxClick, onDelete }) => {
  return (
    <li className="onTop card py-3 my-1 todo rounded-lg">
      <div className="d-flex flex-row w-100">
        <div className="px-2">
          <input className="pointer" type="checkbox" checked={todo.completed} onChange={(evt) => onCheckBoxClick(todo.id, evt.target.checked)} />
        </div>
        <div>{todo.name}</div>
        <DeleteButton className="mx-2 ml-auto pointer" onClick={() => onDelete(todo.id)} />
      </div>
    </li>
  );
});

const SortableList = SortableContainer(({ todos, onCheckBoxClick, onDelete }) => {
  return (
    <ul className="list-unstyled grabbable">
      {todos.map((todo, index) => (
        <SortableItem key={`item-${todo.id}`} index={index} todo={todo} onCheckBoxClick={onCheckBoxClick} onDelete={onDelete} />
      ))}
    </ul>
  );
});
class ToDo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClicked: false,
      todos: [],
      activeTodo: {
        name: null,
        completed: false,
        dateTime: null
      }
    };
  }

  componentDidMount() {
    const { todos } = this.props;
    this.setState({
      todos: todos || []
    });
  }

  addTodo = () => {
    if (isEmptyString(this.state.activeTodo.name)) {
      return;
    }
    const { onChange = () => {} } = this.props;
    const tobeAddedTodo = {
      ...this.state.activeTodo,
      id: uuidv4(),
      dateTime: new Date().toISOString()
    };
    let currentTodos = this.state.todos;
    currentTodos.push(tobeAddedTodo);

    this.setState(
      {
        todos: currentTodos,
        addClicked: false,
        activeTodo: {
          name: null,
          completed: false,
          dateTime: null
        }
      },
      () => {
        onChange(this.state.todos);
      }
    );
  };

  onTodoInputChange = (evt) => {
    this.setState({
      activeTodo: {
        ...this.state.activeTodo,
        name: evt.target.value
      }
    });
  };

  onCheckBoxClick = (id, checked) => {
    let tmpTodos = [];
    this.state.todos.map((todo) => {
      if (todo.id === id) {
        todo.completed = checked;
      }
      tmpTodos.push(todo);
    });
    this.setState({
      todos: tmpTodos
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onChange } = this.props;
    this.setState(({ todos }) => ({
      todos: arrayMove(todos, oldIndex, newIndex)
    }));

    onChange(this.state.todos);
  };

  deleteTodo = (id) => {
    const { onChange } = this.props;
    this.setState(
      {
        todos: this.state.todos.filter((todo) => {
          return todo.id !== id;
        })
      },
      () => {
        onChange(this.state.todos);
      }
    );
  };

  render() {
    const { className = 'pt-4', label } = this.props;
    return (
      <div className={`jumbotron p-1 pt-0 ${className}`}>
        {label ? <label className="mb-1 ml-2">{label}</label> : null}
        <SortableList todos={this.state.todos} onSortEnd={this.onSortEnd} onCheckBoxClick={this.onCheckBoxClick} onDelete={this.deleteTodo} />

        <div className="d-flex flex-row pt-2 m-2">
          {this.state.addClicked ? (
            <React.Fragment>
              <input type="text" className="form-control mr-3" onChange={this.onTodoInputChange} />
              <Button type="success" text={'+'} onClick={this.addTodo} />
            </React.Fragment>
          ) : (
            <Button text={'Add'} type="success" onClick={() => this.setState({ addClicked: true })} />
          )}
        </div>
      </div>
    );
  }
}

ToDo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  onChange: PropTypes.func,
  todos: PropTypes.array
};

export default ToDo;
