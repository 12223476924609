import PropTypes from 'prop-types';
import React from 'react';

import './_deleteButton.scss';

const DeleteButton = (props) => {
  const { className, onClick } = props;
  return (
    <button className={`todo-delete ${className}`} onClick={onClick}>
      &#x2717;
    </button>
  );
};

DeleteButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default DeleteButton;
