import PropTypes from 'prop-types';
import React from 'react';
import firebase from 'firebase/app';

import Avatar from '../../Avatar';
import FirebaseDB from '../../../Database';
import DocumentsUploader from '../../DocumentsUploader';

const MemberProfile = (props) => {
  const {
    member,
    team,
    member: { id: memberId, name, email = 'N/A', filestore = [] },
    team: { name: teamName }
  } = props;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-4 col-md-12">
          <div className="d-none d-md-block">
            <Avatar name={name} size={'large'} />
          </div>
        </div>
        <div className="col-8 col-md-12 m-0">
          <div className="mt-0 row">
            <div className="text-muted col-3 text-left">Name:</div>
            <div className="col-auto text-primary text-left">{name}</div>
          </div>
          <div className="row pt-2">
            <div className="text-muted col-3 text-left">Email:</div>
            <div className="col-auto text-primary text-left">{email}</div>
          </div>
          <div className="row pt-2">
            <div className="text-muted col-3 text-left">Team:</div>
            <div className="col-auto text-primary text-left">{teamName}</div>
          </div>
        </div>
      </div>

      <DocumentsUploader
        onDelete={async (file) => {
          await new FirebaseDB(firebase).deleteMembersFile(file);
          window.location.reload();
        }}
        uploadFilePath={memberId}
        onSuccessCallback={async (file) => {
          await new FirebaseDB(firebase).addToFilestoreForMember(file, team.id, member.id);
          window.location.reload();
        }}
        files={filestore}
      />
    </React.Fragment>
  );
};

MemberProfile.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    filestore: PropTypes.string
  }),
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
};

export default MemberProfile;
