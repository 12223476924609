import PropTypes from 'prop-types';
import React from 'react';
import ProgressiveImage from 'react-progressive-image';

const ProgressiveImageLoader = (props) => {
  const { src, secondarySrc, alt, className } = props;

  return (
    <ProgressiveImage src={src} placeholder={secondarySrc}>
      {(src, loading) => <img className={className} style={{ opacity: loading ? 0.5 : 1 }} src={src} alt={alt} />}
    </ProgressiveImage>
  );
};

ProgressiveImageLoader.propTypes = {
  alt: PropTypes.any,
  className: PropTypes.any,
  secondarySrc: PropTypes.any,
  src: PropTypes.any
};

export default ProgressiveImageLoader;
