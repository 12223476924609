import PropTypes from 'prop-types';
import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './_textArea.scss';

const AdvanceTextArea = (props) => {
  const { onChange, value, className } = props;
  // return <textarea className={'advance-textarea'} type="text" value={value} rows={1} onChange={onChange} />;
  return (
    <div className={className}>
      <CKEditor
        editor={ClassicEditor}
        // https://ckeditor.com/old/forums/CKEditor/Complete-list-of-toolbar-items#comment-123266
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            // 'link',
            'bulletedList',
            'numberedList',
            'indent',
            'outdent',
            'blockQuote'
          ]
        }}
        data={value}
        onInit={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          // console.log({ event, editor, data });
          onChange({ target: { value: data } });
        }}
        onBlur={(event, editor) => {
          // console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          // console.log('Focus.', editor);
        }}
      />
    </div>
  );

  // return <textarea className={'advance-textarea'} type="text" value={value} rows={1} onChange={onChange} />;
};

AdvanceTextArea.propTypes = {
  className: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default AdvanceTextArea;
