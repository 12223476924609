import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './_progress.scss';

const TaskCompleted = (props) => {
  const { percentage = 0, text, badgeText } = props;
  return (
    <div className="task-completed">
      <div className="d-flex justify-content-between align-items-center task-bg">
        {text ? (
          <div className={'d-flex align-items-center'}>
            <div className="m-2">{text}</div>
            <div>
              <h6>
                <div className="mt-2 badge badge-light text-monospace text-muted">{badgeText}</div>
              </h6>
            </div>
          </div>
        ) : null}
        <div className="percentage-body">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              textColor: '#ffff',
              pathColor: '#28a745'
            })}
          />
        </div>
      </div>
    </div>
  );
};

TaskCompleted.propTypes = {
  badgeText: PropTypes.any,
  percentage: PropTypes.number,
  text: PropTypes.any
};

export default TaskCompleted;
