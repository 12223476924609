import React from 'react';
import { Helmet as _Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Helmet = (props) => {
  const { title, metaContent = '' } = props;
  return (
    <_Helmet>
      <meta charSet="utf-8" name="description" content={metaContent} />
      <title>{title}</title>
    </_Helmet>
  );
};

Helmet.propTypes = {
  title: PropTypes.string.isRequired,
  metaContent: PropTypes.string
};

export default Helmet;
