import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteRouter from './containers/SiteRouter';

import './App.css';
import './_App.scss';

const App = () => {
  return (
    <Router>
      <SiteRouter />
    </Router>
  );
};

export default App;
