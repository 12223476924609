import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './_navbar.scss';
class Navbar extends Component {
  isActive = (path) => {
    const { location: { pathname } = {} } = this.props;
    if (path.length === 1) {
      return pathname === path ? 'active active-bar' : '';
    }
    return pathname.startsWith(path) ? 'active active-bar' : '';
  };

  render() {
    const { user, signOut, isAdmin = false } = this.props;

    return (
      <div className={'pb-5 mb-5'}>
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark font-weight-bold navbar-style">
          <div className="container">
            <a className="navbar-brand d-flex flex-row" href="/">
              <div>
                <img src="/img/business-card.svg" alt="" width={40} />
              </div>
              <div className="p-2">eTrack</div>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className={`mt-1 nav-item ${this.isActive('/')}`}>
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className={`mt-1 nav-item ${this.isActive('/contact')}`}>
                  <a className="nav-link" href="/contact">
                    Contact
                  </a>
                </li>
                {isAdmin && (
                  <li className={`mt-1 nav-item ${this.isActive('/admin')}`}>
                    <a className="nav-link" href="/admin">
                      Settings
                    </a>
                  </li>
                )}
                <li className={`nav-item`}>
                  {
                    // user ? <button className="btn btn-outline-success" type="submit" onClick={signOut}>Sign Out</button>
                    // : null
                    user ? (
                      <div className="dropdown">
                        <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img className="md-avatar" src={user.photoURL} alt="" />
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item">{user.displayName}</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href={''} onClick={signOut}>
                            Sign Out
                          </a>
                        </div>
                      </div>
                    ) : null
                  }
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  signOut: PropTypes.any,
  isAdmin: PropTypes.bool,
  user: PropTypes.shape({
    displayName: PropTypes.any,
    photoURL: PropTypes.any
  })
};

export default withRouter(Navbar);
