import PropTypes from 'prop-types';
import React from 'react';
import './_task.scss';
import TaskCompleted from '../Card/TaskCompleted';

const Task = (props) => {
  const { task, onClick } = props;
  if (!task) {
    return null;
  }

  const totalTodos = task.todos && task.todos.length;
  const completedTodos = task.todos && task.todos.filter((todo) => todo.completed === true).length;
  const completedPercentage = totalTodos ? (completedTodos / totalTodos) * 100 : 0;
  const badgeText = totalTodos ? `${completedTodos}/${totalTodos}` : 'N/A';
  const isStatusCompleted = task && task.status && task.status === 'Completed';
  const borderCss = isStatusCompleted ? 'border-success text-muted' : 'border-secondary';

  return (
    <div className={`w-100 text-truncate mt-4 p-2 rounded task shadow-lg pointer`} onClick={onClick}>
      <div className="task-date">
        {new Date(task.dateTime).toLocaleDateString()} : {new Date(task.dateTime).toLocaleTimeString()}
        <div className={`text-uppercase text-right`}>
          <div className={`task-border-top ${borderCss}`}>{task.status}</div>
        </div>
        {task.tags ? (
          <div className={`text-uppercase text-right`}>
            <div className="text-muted">#{task.tags}</div>
          </div>
        ) : null}
      </div>
      <div className={`task-body rounded`} id={task.id}>
        <div dangerouslySetInnerHTML={{ __html: task.value }}></div>
      </div>
      <TaskCompleted percentage={parseInt(completedPercentage)} text={`Tasks Completed`} badgeText={badgeText} />
    </div>
  );
};

Task.propTypes = {
  onClick: PropTypes.any,
  task: PropTypes.shape({
    dateTime: PropTypes.any,
    id: PropTypes.any,
    status: PropTypes.string,
    tags: PropTypes.any,
    todos: PropTypes.array,
    value: PropTypes.any
  })
};

export default Task;
