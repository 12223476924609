export default {
  apiKey: 'AIzaSyAHJlmug5V6ZDz_NepCnvS1j1xEOXL1060',
  // authDomain: "vcard-cc793.firebaseapp.com",
  authDomain: 'etrack.webcontra.com',
  databaseURL: 'https://vcard-cc793.firebaseio.com',
  projectId: 'vcard-cc793',
  storageBucket: 'vcard-cc793.appspot.com',
  messagingSenderId: '711225656342',
  appId: '1:711225656342:web:0b836d659a9fd8977ae137',
  measurementId: 'G-EWPHLX78S8'
};
