import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './_image.scss';
class Image extends Component {
  render() {
    const { name, imgSrc, onClick, height = '142', className } = this.props;
    return (
      <div className={`d-flex flex-column p-1 ${onClick ? 'btn' : ''}`} onClick={onClick}>
        <img className={`mx-auto d-block rounded-boarders ${className}`} src={imgSrc} alt={name} height={height} />
        <div className="text align-self-center">{name}</div>
      </div>
    );
  }
}

Image.propTypes = {
  className: PropTypes.any,
  height: PropTypes.string,
  imgSrc: PropTypes.any,
  name: PropTypes.any,
  onClick: PropTypes.any
};

export default Image;
