import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Database from '../Database';
import ProgressiveImageLoader from '../components/Image/ProgressiveImageLoader';

class Login extends Component {
  constructor() {
    super();
    this.userDb = new Database(firebase);
  }

  render() {
    const { signInWithGoogle } = this.props;

    return (
      <React.Fragment>
        <div className="py-3 d-flex flex-row">
          <div className="mr-auto px-5 d-none d-lg-block col-lg-6 col-xl-7">
            <ProgressiveImageLoader src={'/img/eTrack-2.jpg'} secondarySrc={'/img/eTrack-2-1.jpg'} className={'rounded mx-auto img-fluid'} />
            {/* <img className="rounded mx-auto img-fluid" src="/img/eTrack-2.jpg"/> */}
          </div>
          <div className="col-lg-6 col-xl-5 col-12">
            <div className={'d-flex justify-content-center mt-5 h3'}>Welcome to eTrack</div>
            <div className={'d-flex justify-content-center px-3 py-2 text-success h6 text-muted text-center'}>A perfect solution to plan, monitor and measure the performance of an employee and to better understand the employee expectations.</div>
            <div className="d-flex justify-content-center my-5">
              <img className="rounded mx-auto d-block" width={140} height={140} src={'/img/business-card.svg'} alt="" />
            </div>
            <div className="d-flex justify-content-center mt-2 pb-5 h2">
              <button onClick={signInWithGoogle} className="btn btn-outline-success">
                <img src="/img/btn_google_light_focus_ios.svg" width="60" alt="" />
                <a className="pr-4" style={{ fontSize: 'large' }}>
                  Sign in with Google
                </a>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  signInWithGoogle: PropTypes.any
};

export default Login;
