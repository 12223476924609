import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import firebaseConfig from '../firebaseConfig';
import { Switch, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Profile from './Profile';
import Member from './Member';
import User from './User';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import Admin from './Admin';
import NoMatch from '../components/NoMatch';
import ContactUs from './ContactUs';
import Login from './Login';
import { fetchAdminUsers } from '../redux/modules/admin/actions';

const firebaseApp = firebase.initializeApp(firebaseConfig);
// eslint-disable-next-line
function PrivateRoute({ component: Component, user, ...rest }) {
  if (!user) {
    return null;
  }
  return <Route {...rest} render={(routeProps) => <Component {...routeProps} user={user} />} />;
}
class SiteRouter extends Component {
  constructor() {
    super();
    this.state = {
      isAdmin: undefined
    };
  }
  componentDidUpdate() {
    const { user, admin, fetchAdminUsers } = this.props;
    user && admin.isFetching === undefined && fetchAdminUsers(user.uid);
    if (admin.users !== undefined && this.state.isAdmin === undefined) {
      let userFoundAsAdmin = false;
      Object.keys(admin.users).map((key) => {
        if (admin.users[key].isAdmin && admin.users[key].uid === user.uid) {
          userFoundAsAdmin = true;
        }
      });

      this.setState({
        isAdmin: userFoundAsAdmin
      });
    }
  }
  render() {
    const { user } = this.props;
    if (typeof user !== 'object') {
      return <Loader />;
    }
    return (
      <React.Fragment>
        <Navbar {...this.props} isAdmin={this.state.isAdmin} />
        <main>
          <Switch>
            {user ? <PrivateRoute exact path="/" user={user} component={Profile} /> : <Route exact path="/" render={() => <Login {...this.props} />} />}

            <PrivateRoute exact path="/profile/:userId" user={user} component={User} />
            <PrivateRoute exact path="/admin" user={user} component={Admin} />
            <PrivateRoute exact path="/:teamId/:memberId" user={user} component={Member} />
            <Route exact path={'/contact'} render={() => <ContactUs {...this.props} />} />
            <Route component={NoMatch} />
          </Switch>
        </main>
        <div className="pt-5">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

SiteRouter.propTypes = {
  user: PropTypes.object,
  admin: PropTypes.object,
  fetchAdminUsers: PropTypes.func
};

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};

providers.googleProvider.setCustomParameters({ prompt: 'select_account' });

function mapDispatchToProps(dispatch) {
  return {
    fetchAdminUsers: (id) => dispatch(fetchAdminUsers(id))
  };
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

const _SiteRouter = connect(mapStateToProps, mapDispatchToProps)(SiteRouter);
export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(_SiteRouter);
