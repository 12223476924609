import { handleActions as reducer } from 'redux-actions';
import { REQUEST_ADMIN_USERS, RECEIVED_ADMIN_USERS } from './actions';

import { handleReceive, handleRequest } from '../../selectors';

const handleRequestAdminUsers = (state) => {
  return {
    ...handleRequest(state)
  };
};

const handleReceiveAdminUsers = (state, action) => {
  return {
    ...handleReceive(state),
    users: action.payload
  };
};

export default reducer(
  {
    [REQUEST_ADMIN_USERS]: handleRequestAdminUsers,
    [RECEIVED_ADMIN_USERS]: handleReceiveAdminUsers
  },
  {}
);
