import PropTypes from 'prop-types';
import React from 'react';
import firebase from 'firebase/app';
import Image from '../../Image';
import DocumentsUploader from '../../DocumentsUploader';
import FirebaseDB from '../../../Database';

const UserProfile = (props) => {
  const { user, files = [] } = props;
  return (
    <React.Fragment>
      {/* <div className="d-none d-sm-none d-md-block"> */}
      <div className="m-0 p-0">
        <div className="row">
          <div className="col-4 col-md-12">
            <Image className="img-thumbnail img-fluid" name={user.diplayName} imgSrc={user.photoURL} height="250" />
          </div>
          <div className="col-8 col-md-12">
            <div className="col-12 m-2 mt-0">
              <div className="row">
                <div className="text-muted col-3 text-left">Name:</div>
                <div className="col-auto text-primary text-left">{user.displayName}</div>
              </div>
            </div>
            <div className="col-12 m-2">
              <div className="row">
                <div className="text-muted col-3 text-left">Email:</div>
                <div className="col-auto text-primary text-left">{user.email}</div>
              </div>
            </div>
            <div className="col-12 m-2">
              <div className="row">
                <div className="text-muted col-3 text-left">Login:</div>
                <div className="col-auto text-primary text-left">{new Date(user.metadata.lastSignInTime).toLocaleString()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DocumentsUploader
        onDelete={async (file) => {
          await new FirebaseDB(firebase).deleteLoggedInUserFiles(file);
          window.location.reload();
        }}
        files={files}
        onSuccessCallback={async (file) => {
          await new FirebaseDB(firebase).addToFilestore(file);
          window.location.reload();
        }}
      />
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  files: PropTypes.array,
  user: PropTypes.shape({
    diplayName: PropTypes.any,
    displayName: PropTypes.any,
    email: PropTypes.any,
    metadata: PropTypes.shape({
      lastSignInTime: PropTypes.any
    }),
    photoURL: PropTypes.any
  })
};

export default UserProfile;
