import React from 'react';

const Footer = () => {
  return (
    <div className="p-2 align-items-start fixed-bottom d-none d-lg-block">
      <small className="text-muted ">
        <div className="d-flex flex-row">
          <div>Version</div>
          <span aria-hidden="true" role="presentation">
            &nbsp;·&nbsp;
          </span>
          <div>Beta - 2020</div>
        </div>
        <div className="d-flex flex-row">
          <a className="text-muted" href="https://www.linkedin.com/in/haseebzulfiqar/">
            LinkedIn
          </a>
          <span aria-hidden="true" role="presentation">
            &nbsp;·&nbsp;
          </span>
          <div>Copyright © Webcontra 2020.</div>
        </div>
      </small>
    </div>
  );
};

export default Footer;
