import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchTeams, addTeamMemberTask, updateTeamMemberTask, deleteTeamMemberTask } from '../redux/modules/teams/actions';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import MemberProfile from '../components/Panel/MemberProfile';
import Button from '../components/Button';
import Task from '../components/Task';
import AdvanceTextArea from '../components/AdvanceTextArea';
import EditTask from '../components/EditTask';
import Helmet from '../components/Helmet';
import { Tabs, Tab } from 'react-bootstrap';
import Avatar from '../components/Avatar';
import Tile from '../components/Tile';
import Loader from '../components/Loader';

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textArea: '',
      textAreaType: 'dark',
      editTask: {
        isEditing: false,
        teamId: null,
        memberId: null,
        taskId: null,
        taskText: null
      }
    };
  }
  componentDidMount() {
    this.props.fetchTeams();
  }

  onSaveItem = () => {
    if (this.state.textArea === null || this.state.textArea.length === 0) {
      return null;
    }
    const { selectedMember, selectedTeam } = this.props;
    this.props.addTeamMemberTask(selectedTeam.id, selectedMember.id, {
      value: this.state.textArea
    });
    this.resetState();
  };

  resetState = () => {
    this.setState(
      {
        textArea: '',
        textAreaType: 'dark',
        editTask: {
          isEditing: false,
          teamId: null,
          memberId: null,
          taskId: null,
          taskText: null
        }
      }
      // ,
      // () => this.props.fetchTeams()
    );
  };

  onTextAreaChange = (event) => {
    this.setState({
      textArea: event.target.value,
      textAreaType: event.target.value.length > 0 ? 'success' : 'dark'
    });
  };

  onTaskClick = (teamId, memberId, task) => {
    const { selectedMember, teams } = this.props;
    this.setState({
      editTask: {
        isEditing: true,
        teamId,
        memberId,
        task
      }
    });
  };

  groupTasksByYear = (data) => {
    if (!data) {
      return {};
    }
    let groups = {};

    data.forEach(function (val) {
      let date = val.dateTime.split('-')[0];
      if (date in groups) {
        groups[date].push(val);
      } else {
        groups[date] = new Array(val);
      }
    });
    return groups;
  };

  groupByKeyValue = (data, key) => {
    if (!data) {
      return {};
    }
    return data.reduce(function (r, a) {
      r[a[key] || 'Uncategorized'] = r[a[key]] || [];
      r[a[key] || 'Uncategorized'].push(a);
      return r;
    }, Object.create(null));
  };

  render() {
    const { selectedMember, selectedTeam, teams: { isFetching } = {} } = this.props;
    if (!selectedMember) {
      return null;
    }
    let groupedTasks = Object.keys(this.groupByKeyValue(selectedMember.tasks, 'tags'));
    return (
      <React.Fragment>
        <Helmet title={`eTrack - Tasks`} />
        {isFetching ? <Loader /> : null}
        <Layout
          leftContent={<MemberProfile member={selectedMember} team={selectedTeam} />}
          rightContent={
            <div>
              <AdvanceTextArea value={this.state.textArea} onChange={this.onTextAreaChange} />
              <Button className="pt-4" text="Save" onClick={this.onSaveItem} type={this.state.textAreaType} />
              <Tabs className="pt-4" defaultActiveKey="All">
                {groupedTasks.map((key, index) => {
                  const _tasks = this.groupByKeyValue(selectedMember.tasks, 'tags')[key];
                  return (
                    <Tab key={key} eventKey={key} title={key}>
                      {_tasks.map((task, index) => {
                        return <Task key={index} task={task} onClick={() => this.onTaskClick(selectedTeam.id, selectedMember.id, task)} />;
                      })}
                    </Tab>
                  );
                })}
                {/* <Tab eventKey="Years" title="Years">
                  <div className="d-flex flex-wrap">
                    {Object.keys(this.groupTasksByYear(selectedMember.tasks)).map((key, index) => {
                      return <Tile key={index} className="p-2" text={key} onClick={() => {}} />;
                    })}
                  </div>
                </Tab> */}
                <Tab eventKey="All" title="All">
                  {!selectedMember.tasks || selectedMember.tasks.length === 0 ? (
                    <div className="w-100 p-3 justify-content-between align-items-center text-muted text-center">No Goals</div>
                  ) : (
                    selectedMember.tasks.map((task, index) => {
                      return <Task key={index} task={task} onClick={() => this.onTaskClick(selectedTeam.id, selectedMember.id, task)} />;
                    })
                  )}
                </Tab>
              </Tabs>

              {this.state.editTask.isEditing ? (
                <EditTask
                  show={this.state.editTask.isEditing}
                  task={JSON.parse(JSON.stringify(this.state.editTask.task))}
                  onDelete={(taskId) => {
                    this.props.deleteTeamMemberTask(this.state.editTask.teamId, this.state.editTask.memberId, taskId).then(() => {
                      this.resetState();
                    });
                  }}
                  onSave={(updatedTask) => {
                    this.props.updateTeamMemberTask(this.state.editTask.teamId, this.state.editTask.memberId, this.state.editTask.task.id, updatedTask).then(() => {
                      this.resetState();
                    });
                  }}
                  onClose={() => {
                    this.resetState();
                  }}
                />
              ) : null}
            </div>
          }
        />
      </React.Fragment>
    );
  }
}

Member.propTypes = {
  addTeamMemberTask: PropTypes.func,
  deleteTeamMemberTask: PropTypes.func,
  fetchTeams: PropTypes.func,
  selectedMember: PropTypes.object,
  selectedTeam: PropTypes.shape({
    id: PropTypes.any
  }),
  teams: PropTypes.any,
  updateTeamMemberTask: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    fetchTeams: () => {
      dispatch(fetchTeams());
    },
    addTeamMemberTask: (teamId, memberId, task) => {
      dispatch(addTeamMemberTask(teamId, memberId, task));
    },
    updateTeamMemberTask: (teamId, memberId, taskId, updatedTask) => {
      return dispatch(updateTeamMemberTask(teamId, memberId, taskId, updatedTask));
    },
    deleteTeamMemberTask: (teamId, memberId, taskId) => {
      return dispatch(deleteTeamMemberTask(teamId, memberId, taskId));
    }
  };
}

function mapStateToProps(state, ownProps) {
  const { match: { params = {} } = {} } = ownProps;
  const memberId = params.memberId;
  const teamId = params.teamId;

  const { teams: { list = [] } = {} } = state;
  let selectedTeam = null,
    selectedMember = null;
  list.map((team) => {
    if (team.id === teamId) {
      selectedTeam = team;
      team.members.map((member) => {
        if (member.id === memberId) {
          selectedMember = member;
        }
        return member;
      });
    }
    return team;
  });

  return {
    ...state,
    selectedTeam,
    selectedMember
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Member));
