import PropTypes from 'prop-types';
import React from 'react';

const Layout = (props) => {
  const { leftContent, rightContent } = props;
  return (
    <div className={'container mt-0'}>
      <small className="row pt-4 ">
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center">{leftContent}</div>
        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">{rightContent}</div>
      </small>
    </div>
  );
};

Layout.propTypes = {
  leftContent: PropTypes.any,
  rightContent: PropTypes.any
};

export default Layout;
