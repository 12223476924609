import PropTypes from 'prop-types';
import React, { Component } from 'react';

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false
    };
  }
  render() {
    const { className, placeholder = '', value, onChange } = this.props;

    return (
      <div className={className} onClick={() => this.setState({ isEditing: true })}>
        {this.state.isEditing ? (
          <input
            className="form-control py-0 m-0"
            type="text"
            autoFocus={true}
            placeholder={` ${placeholder}`}
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
            onBlur={() => {
              this.setState({ isEditing: false });
            }}
          />
        ) : (
          <div className="text-muted">#{value || placeholder}</div>
        )}
      </div>
    );
  }
}

TextInput.propTypes = {
  className: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any
};
export default TextInput;
